export const colors = {
  red: '#D81F28',
  white: 'white',
  blue: '#296DB3',
  text: '#66788A',
  lightBlue: '#9DBCDC',
  faintBlue: '#F4F7FB',
  lightgrey: '#A0A0A0',
  grey: '#A1A1A1',
};
